import { useState, FormEvent } from 'react';
import { Alert, Backdrop, Box, Button, CircularProgress, Grid, Snackbar, TextField, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import { SearchOutlined } from '@mui/icons-material';
import { DatePicker, LocalizationProvider, MobileDatePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ptBRLocale from 'date-fns/locale/pt-BR';
import DateFormat from 'date-fns/format';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';

type ErrorType = {
  titulo: string,
  status: number,
  timestamp: number,
  mensagemDesenvolvedor: string;
}

type SerieEnumType = {
  id: string;
  descricao: string;
}

type SerieIdadeType = {
  ano: number;
  mes: number;
  dia: number;
  serieEnum: SerieEnumType
}

export function DescobrirSerie() {

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const [serieIdade, setSerieIdade] = useState<SerieIdadeType | null>({
    ano: 0,
    mes: 0,
    dia: 0,
    serieEnum: {
      id: "NONE",
      descricao: "Nenhuma série compatível com a idade calculada",
    }
  });
  const [dataNascimento, setDataNascimento] = useState<Date | null>(new Date());
  const [dataCorte, setDataCorte] = useState<Date | null>(new Date("03/31/2022"));
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openBackdrop, setopenBackdrop] = useState(false);


  const handleCloseBackDrop = () => {
    setopenBackdrop(false);
  };

  const handleOpenBrackDrop = () => {
    setopenBackdrop(!openBackdrop);
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  function searchSerieIdade(dataCorte: string, dataNascimento: string) {
    let url = `https://api.jlucasguedes.com.br/buscaescola/descobrir-serie/${dataCorte}/${dataNascimento}`;
    handleOpenBrackDrop();
    fetch(url).then(response => {
      if (response.ok) {
        handleCloseBackDrop();
        return response.json();
      } else {
        return response.text().then(text => { throw new Error(text) })
      }
    }).then(data => {
      setSerieIdade(data);

      if (serieIdade?.serieEnum.descricao == 'Nenhuma série compatível com a idade calculada') {
        handleOpenSnackbar();
      }
    }).catch((error) => {
      handleCloseBackDrop();
    });
  }

  async function handleDescobrirIdade(event: FormEvent) {
    event.preventDefault();
    handleCloseBackDrop();
    searchSerieIdade(DateFormat(dataCorte!, "dd-MM-yyyy"), DateFormat(dataNascimento!, "dd-MM-yyyy"));
  }

  function formatAnoDate(ano: number | undefined) {
    if (ano == 0 || ano == undefined) {
      return "";
    } else if (ano == 1) {
      return `${ano} ano`;
    } else {
      return `${ano} anos`;
    }
  }

  function formatMesDate(mes: number | undefined) {
    if (mes == 0 || mes == undefined) {
      return "";
    } else if (mes == 1) {
      return `${mes} mês`;
    } else {
      return `${mes} meses`;
    }
  }

  function formatDiaDate(dia: number | undefined) {
    if (dia == 0 || dia == undefined) {
      return "";
    } else if (dia == 1) {
      return `e ${dia} dia`;
    } else {
      return `e ${dia} dias`;
    }
  }



  return (
    <div>
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <Alert variant="filled" onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
          {serieIdade?.serieEnum.descricao}
        </Alert>
      </Snackbar>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleCloseBackDrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ResponsiveAppBar />
      <main>
        <form onSubmit={handleDescobrirIdade}>
          <Box sx={{ flexGrow: 1 }} marginTop={2}>
            <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
              <LocalizationProvider locale={ptBRLocale} dateAdapter={AdapterDateFns}>
                <Grid item xs={12} alignItems="center" flexDirection="column">
                  <Item>
                    <MobileDatePicker
                      label="Data corte"
                      value={dataCorte}
                      onChange={(newValue) => {
                        setDataCorte(newValue);
                      }}
                      disabled
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </Item>
                </Grid>
                <Grid item xs={12} alignItems="center" flexDirection="column" textAlign="center">
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent>
                      <MobileDatePicker
                        disableFuture
                        label="Data de nascimento"
                        value={dataNascimento}
                        onChange={(newValue) => {
                          setDataNascimento(newValue);
                        }}
                        inputFormat="dd/MM/yyyy"
                        mask="__/__/____"
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </CardContent>
                  </Card>
                </Grid>
              </LocalizationProvider>
              <Grid item xs={12}>
                <Item>
                  <Button type="submit" variant="contained" startIcon={<SearchOutlined />}>Descobrir</Button>
                </Item>
              </Grid>
            </Grid>
          </Box>
          <div>
            <Box sx={{ width: '100%' }} marginTop={1} padding={2}>
              <Paper sx={{ width: '100%' }}>
                <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
                  <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom marginLeft={1}>
                      <b> Idade calculada:</b> {`${formatAnoDate(serieIdade?.ano)} ${formatMesDate(serieIdade?.mes)} ${formatDiaDate(serieIdade?.dia)}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1" gutterBottom marginLeft={1}>
                      <b>Turma correspondente a idade:</b> {serieIdade?.serieEnum.descricao}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          </div>
        </form>
      </main>
    </div>
  );
}