
import { HashRouter as Router, Route, Routes, } from "react-router-dom";


import { DescobrirSerie } from "./pages/DescobrirSerie";
import { BuscaEscola } from "./pages/BuscaEscola";

function App() {
  return (
    <Router>
      <Routes>
        <Route index element={<BuscaEscola />} />
        <Route path="/descobrir-serie" element={<DescobrirSerie />} />
      </Routes>
    </Router>
  );
}

export default App;
