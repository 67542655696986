import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';


type ModalidadeType = {
  id: string;
  descricao: string;
}

type TurnoType = {
  id: string;
  descricao: string;
}

type SerieType = {
  id: number;
  nome: string;
  modalidade: ModalidadeType;
}

type RegiaoType = {
  id: number;
  descricao: string;
}

type EnderecoType = {
  id: number;
  logradouro: string;
  bairro: string;
  regiao: RegiaoType;
}

type TurmaType = {
  id: number;
  nome: string;
  serie: SerieType;
  turno: TurnoType;
}

type UnidadeEducacionalType = {
  id: number;
  nome: string;
  telefone: string;
  endereco: EnderecoType;
  turmas: Array<TurmaType>;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: "#8adaff",
  },
}));

function Row(props: { row: UnidadeEducacionalType }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <StyledTableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <b>{row.nome}</b>
        </TableCell>
        <TableCell>{row.endereco.regiao.descricao}</TableCell>
        <TableCell>{row.endereco.bairro}</TableCell>
        <TableCell>{row.endereco.logradouro}</TableCell>
        <TableCell>{row.telefone}</TableCell>
      </StyledTableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Séries atendidas
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Modalidade</TableCell>
                    <TableCell>Turno</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.turmas.map((turmaRow) => (
                    <TableRow key={turmaRow.nome + "" + turmaRow.turno}>
                      <TableCell component="th" scope="row">{turmaRow.nome}</TableCell>
                      <TableCell component="th" scope="row">{turmaRow.serie.modalidade.descricao}</TableCell>
                      <TableCell>{turmaRow.turno.descricao}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
type CollapsibleTableProps = {
  unidades: Array<UnidadeEducacionalType>;
}
export default function CollapsibleTable(props: CollapsibleTableProps) {

  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Nome</TableCell>
            <TableCell>Região</TableCell>
            <TableCell>Bairro</TableCell>
            <TableCell>Endereço</TableCell>
            <TableCell>Telefone</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.unidades.map((row) => (
            <Row key={row.nome} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
