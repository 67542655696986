import React, { useEffect, useState, FormEvent } from 'react';
import { Autocomplete, Backdrop, Box, Button, CircularProgress, Collapse, Grid, IconButton, Snackbar, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import ResponsiveAppBar from '../components/ResponsiveAppBar';
import CollapsibleTable from '../components/CollapsibleTable';
import { SearchOutlined } from '@mui/icons-material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

type ModalidadeType = {
  id: string;
  descricao: string;
}

type TurnoType = {
  id: string;
  descricao: string;
}

type SerieType = {
  id: number;
  nome: string;
  modalidade: ModalidadeType;
}

type RegiaoType = {
  id: number;
  descricao: string;
}

type EnderecoType = {
  id: number;
  logradouro: string;
  bairro: string;
  regiao: RegiaoType;
}

type TurmaType = {
  id: number;
  nome: string;
  serie: SerieType;
  turno: TurnoType;
}

type UnidadeEducacionalType = {
  id: number;
  nome: string;
  telefone: string;
  endereco: EnderecoType;
  turmas: Array<TurmaType>;
}

export function BuscaEscola() {

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const modalidades = [
    { descricao: 'Educação de Jovens e Adultos', id: 'EJA' },
    { descricao: 'Educação Infantil', id: 'EDUCACAO_INFANTIL' },
    { descricao: 'Ensino Fundamental', id: 'ENSINO_FUNDAMENTAL' },
  ]


  const turnos = [
    { descricao: 'Parcial', id: 'PARCIAL' },
    { descricao: 'Integral', id: 'INTEGRAL' },
    { descricao: 'Noturno', id: 'NOTURNO' },
  ]

  const [modalidade, setModalidade] = useState<ModalidadeType>();
  const [turno, setTurno] = useState<TurnoType | null>(null);
  const [serie, setSerie] = useState<SerieType | null>(null);
  const [listSerie, setListSerie] = useState<SerieType[]>([]);
  const [regiao, setRegiao] = useState<RegiaoType | null>(null);
  const [listRegiao, setListRegiao] = useState<RegiaoType[]>([]);

  const [listUnidades, setListUnidades] = useState<Array<UnidadeEducacionalType>>([]);

  function loadSerie(modalidadeSelecionada: ModalidadeType) {
    let url = 'https://api.jlucasguedes.com.br/buscaescola/series/' + modalidadeSelecionada.id;
    fetch(url).then(response => response.json())
      .then(data => {
        setListSerie(data);
        handleClose();
      })
  }

  function loadRegiao() {
    let url = 'https://api.jlucasguedes.com.br/buscaescola/regioes';
    fetch(url).then(response => response.json())
      .then(data => {
        handleClose();
        setListRegiao(data);
      })
  }

  function searchUnidades(regiao: RegiaoType, serie: SerieType, turno: TurnoType) {
    let url = `https://api.jlucasguedes.com.br/buscaescola/unidades/${regiao.id}/${serie.id}/${turno.id}`;
    fetch(url).then(response => response.json())
      .then(data => {
        handleClose();
        setListUnidades(data);
        if (data.length == 0) {
          handleOpenSnackbar();
        } else {
          handleCloseSnackbar();
        }
      })
  }

  useEffect(() => {
    loadRegiao();
  }, []);

  useEffect(() => {
    if (modalidade) {
      loadSerie(modalidade);
    }
  }, [modalidade]);

  async function handlePequisarUnidade(event: FormEvent) {
    event.preventDefault();
    handleToggle();
    setListUnidades([]);
    searchUnidades(regiao!, serie!, turno!);
  }

  const [openBackdrop, setopenBackdrop] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleOpenSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleClose = () => {
    setopenBackdrop(false);
  };

  const handleToggle = () => {
    setopenBackdrop(!openBackdrop);
  };

  const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackdrop}
        onClick={handleClose}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <ResponsiveAppBar />
      <main>
        <form onSubmit={handlePequisarUnidade}>
          <Box sx={{ flexGrow: 1 }} marginTop={2}>
            <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
              <Grid item xs={12} alignItems="center" flexDirection="column">
                <Item>
                  <Autocomplete
                    disablePortal
                    disableClearable={true}
                    id="combo-box-demo"
                    options={modalidades}
                    value={modalidade}
                    getOptionLabel={(option) => option.descricao || ""}
                    onChange={(event, novaModalidade) => {
                      handleToggle();
                      setSerie(null);
                      setModalidade(novaModalidade);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => <TextField {...params} label="Modalidade" required={modalidade == null} />}
                  />
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={listSerie}
                    value={serie}
                    getOptionLabel={(option) => option.nome || ""}
                    onChange={(event, novaSerie) => {
                      setSerie(novaSerie);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => <TextField {...params} label="Série" required={serie == null} />}
                  />
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={listRegiao}
                    value={regiao}
                    getOptionLabel={(option) => option.descricao || ""}
                    onChange={(event, novaRegiao) => {
                      setRegiao(novaRegiao);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => <TextField {...params} label="Região" required={regiao == null} />}
                  />
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={turnos}
                    value={turno}
                    getOptionLabel={(option) => option.descricao || ""}
                    onChange={(event, novoTurno) => {
                      setTurno(novoTurno);
                    }}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderInput={(params) => <TextField {...params} label="Turno" required={turno == null} />}
                  />
                </Item>
              </Grid>
              <Grid item xs={12}>
                <Item>
                  <Button type="submit" variant="contained" startIcon={<SearchOutlined />}>Pesquisar</Button>
                </Item>
              </Grid>
            </Grid>
          </Box>
        </form>
      </main>
      <div className="datagrid-unidades">
        <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}>
          <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
            Nenhuma unidade educacional encontrada
          </Alert>
        </Snackbar>
        <Box sx={{ width: '100%' }} marginTop={1} padding={2}>
          <Paper sx={{ width: '100%' }}>
            <Grid container spacing={2} alignItems="center" justifyContent="flex-start">
              <Grid item xs={12}>
                <CollapsibleTable unidades={listUnidades} />
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </div>
    </div>
  );
}

